
import {Vue, Component} from 'vue-property-decorator';
import ChatMenu from "@/components/menu/ChatMenu.vue";
import TMenuSection from "@/components/lib/menu/TMenuSection.vue";
import ProfileAvatar from "@/components/profile/ProfileAvatar.vue";
import { name, version } from '@/../package.json'

@Component({
  components: {ProfileAvatar, TMenuSection, ChatMenu}
})
export default class Menu extends Vue {
  get version() {
    return version
  }

  get name() {
    return name
  }

  get year() {
    const date = new Date()
    return date.getFullYear()
  }
}
