
import {Vue, Component} from 'vue-property-decorator';
// import TMenuSection from "@/components/lib/menu/TMenuSection.vue";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Channel} from "@/models";
import {TMenuItem} from "@/types/lib/TMenuTypes";
import TMenuSection from "@/components/lib/menu/TMenuSection.vue";

@Component({
  components: {TMenuSection}
})
export default class ChatMenu extends Vue {
  static CHAT_ACCESS_ROLE = 'operator';

  private channelItems: TMenuItem[] = [];
  private staticItems: TMenuItem[] = [
    {
      to: { name: 'allChats' },
      title: 'Все чаты',
      // icon: 'fa-regular fa-bookmark',
      // iconSize: 'xs',
      exact: true
    },
    {
      to: { name: 'chatsByChannel', params: { channelName: 'bookmarks'} },
      title: 'Закладки',
      icon: 'fa-regular fa-bookmark',
      iconSize: 'xs',
      exact: true
    },
    {
      to: { name: 'chatsByChannel', params: { channelName: 'conversations'} },
      title: 'Мои переписки',
      icon: 'fa-regular fa-message',
      iconSize: 'xs',
      exact: true
    }
  ]


  private channels: ResourceCollection<Channel> = new ResourceCollection (
      Channel.api().page(1).perPage(25).orderBy('userName'),
      'chat-menu-channels'
  )

  mounted() {
    //console.log('{ ChatMenu#mounted } as operator: ', this.hasChannelAccess)
    //console.log('{ ChatMenu#mounted } staticItems: ', this.staticItems)
    //console.log('{ ChatMenu#mounted } channelItems: ', this.channelItems)
    if (this.hasChannelAccess) {
      this.channels.requestItems().then(() => {
        this.channelItems = this.channels
            .items
            .map(channel => {
              return {
                to: { name: 'chatsByChannel', params: { channelName: channel.userName as string }},
                title: '@' + channel.userName as string
              }
            })
        //console.log('{ ChatMenu#mounted } channelItems [after load]: ', this.channelItems)
      })
    }
  }

  get hasChannelAccess(): boolean {
    return !!this.$keycloak.hasRealmRole?.(ChatMenu.CHAT_ACCESS_ROLE)
  }

  get _staticItems(): TMenuItem[] {
    return [
      {
        to: { name: 'allChats' },
        title: 'Все чаты',
        // icon: 'fa-regular fa-bookmark',
        // iconSize: 'xs',
        exact: true
      },
      {
        to: { name: 'chatsByChannel', params: { channelName: 'bookmarks'} },
        title: 'Закладки',
        icon: 'fa-regular fa-bookmark',
        iconSize: 'xs',
        exact: true
      },
      {
        to: { name: 'chatsByChannel', params: { channelName: 'conversations'} },
        title: 'Мои переписки',
        icon: 'fa-regular fa-message',
        iconSize: 'xs',
        exact: true
      }
    ]
  }
  get _channelItems(): TMenuItem[] {
    if (!this.channels.$isLoading && this.channels.items.length) {
      return this.channels
          .items
          .map(channel => {
            return {
              to: { name: 'chatsByChannel', params: { channelName: channel.userName as string }},
              title: '@' + channel.userName as string
            }
          })
    }
    return []
  }
}
