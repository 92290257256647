
import {Vue, Component, Prop} from 'vue-property-decorator';
import {RawLocation} from "vue-router";
import {TMenuItem} from "@/types/lib/TMenuTypes";

@Component({
  name: 'TMenuSection',
  components: {TMenuSection}
})
export default class TMenuSection extends Vue {
  @Prop() private to!: RawLocation
  @Prop() private pathPrefix!: string
  @Prop({ default: false, type: Boolean }) private exact!: boolean
  @Prop() private title!: string
  @Prop() private icon!: string
  @Prop() private iconSize!: string
  @Prop() private iconClass!: string
  @Prop({ default: false, type: Boolean }) private open!: boolean
  @Prop({ default: true, type: Boolean }) private childLine!: boolean
  @Prop() private child!: TMenuItem[]
  @Prop({ default: '1' }) private keyIndex!: string
  @Prop({ default: false, type: Boolean }) private isLoading!: boolean

  private isOpen = false

  mounted() {
    this.isOpen = this.open;
  }

  toggleOpen() {
    this.isOpen = !this.isOpen
  }

  get isActiveRoute(): boolean {
    return (!!this.pathPrefix && this.$route.path.startsWith(this.pathPrefix))
  }
}
