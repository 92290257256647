import SockJS, {CloseEvent} from "sockjs-client";
import Stomp, {Client, Frame} from "webstomp-client";
import {StompSubscription} from "@/types/StompTypes";
import Vue from "vue";

export class StompService {
    private socket!: WebSocket;
    private stompClient!: Client;

    private connected = false;
    private connecting = false;
    private reconnectByTimeout: number | null = null;
    public connectionsAttempt = 0;

    private errorShown = false;

    private subscriptions = Array<StompSubscription>();

    get $isConnected(): boolean {
        if (!this.socket && !this.stompClient) {
            return false
        }
        return this.connected
    }
    get $isConnecting(): boolean {
        if (!this.socket && !this.stompClient) {
            return false
        }
        return this.connecting
    }

    get attempt () {
        return this.connectionsAttempt
    }


    public init() {
        //console.log('[ WSClient ] Init')
        this.socket = new SockJS(window.location.origin + `/ws?access_token=${Vue.prototype.$keycloak.token}`);
        this.stompClient = Stomp.over(this.socket, { debug: false });
    }
    $connect() {
        this.init()
        this.connectionsAttempt++;
        console.log('[ WSClient ] Try to connect.., attempt: ' + this.connectionsAttempt)
        this.connecting = true;
        this.stompClient.connect(
            {},
            frame => {
                this.onConnectionSuccess(frame)
            },
            error => {
                this.onConnectionError(error)
            }
        )
    }

    onConnectionSuccess(frame: Frame | undefined) {
        console.log('[ WSClient ] Connected.')
        const vm = new Vue()
        this.connected = true;
        this.connecting = false;

        const sub = vm.$keycloak?.idTokenParsed?.sub
        const username = vm.$keycloak?.idTokenParsed?.preferred_username
        //console.log('[ WSClient ] Keycloak authenticated:', vm.$keycloak.authenticated, 'sub:', sub)

        /* соединение восстановлено */
        if (this.errorShown) {
            vm.$bvToast.toast('Соединение восстановлено', {
                title: 'Сервер сообщений',
                variant: 'success',
                noCloseButton: false
            })
            this.errorShown = false
        }

        /* подписки при новом соединении */
        if (!this.subscriptions.length) {
            if (username) {
                this.$subscribe({
                    subscriptionName: 'public',
                    topicName: '/user/' + sub + '/queue/messages',
                    callback: this.showToast
                })
            }
            this.$subscribe({
                subscriptionName: 'task',
                topicName: sub ? `/task/${sub}` : '/task/public',
                callback: this.showToast
            })
        }
        /* повторная подписка при восстановлении соединения */
        else {
            this.subscriptions.forEach(sub => {
                if (sub.subscription) {
                    sub.subscription.unsubscribe()
                }
                sub.subscription = this.stompClient.subscribe(sub.topicName, (message) => {
                    //console.log('[ WSClient ] Receive message for: ' + sub.topicName, 'Message:', message)
                    const body = JSON.parse(message.body);
                    if (sub.callback && typeof sub.callback === 'function') {
                        sub.callback(body);
                    }
                })
                //console.log('[ WSClient ] Re-Subscribed to: ' + sub.topicName)
            })
        }

    }
    onConnectionError(error: Frame | CloseEvent) {
        console.error('[ WSClient ] error: ', error);
        if (!this.errorShown) {
            const vm = new Vue()
            const message = this.connected
                ? 'Потеряно соединение. Переподключение через 10 сек.'
                : 'Ошибка подключения. Повторная попытка через 10 сек.'
            vm.$bvToast.toast(message, {
                title: 'Сервер сообщений',
                variant: 'danger',
                noCloseButton: false
            })
            this.errorShown = true
        }

        this.connected = false;

        this.reconnectByTimeout = setTimeout(() => {
            console.warn('[ WSClient ] Reconnecting to server...');
            this.$connect()
        }, 10000);
    }

    showToast(data: any) {
        //console.warn("[ WSClient ] Message data:", data)
    }

    getSubscription(name: string) {
        return this.subscriptions.find((sub) => {
            return sub.subscriptionName === name
        })
    }

    $subscribe(newSubscription: StompSubscription): StompSubscription | null {
        const aliveSubscription = this.subscriptions.find((sub) => {
            return sub.subscriptionName === newSubscription.subscriptionName
        })
        if (aliveSubscription) {
            //console.warn("[ WSClient ] Subscription exist: " + newSubscription.subscriptionName)
            return aliveSubscription
        }
        if (this.stompClient && this.stompClient.connected) {
            newSubscription.subscription = this.stompClient.subscribe(newSubscription.topicName, (message) => {
                // console.log('[ WSClient ] Receive message for: ' + newSubscription.topicName, message)
                const body = JSON.parse(message.body);
                if (newSubscription.callback && typeof newSubscription.callback === 'function') {
                    newSubscription.callback(body);
                }
            });
            //console.log('[ WSClient ] Subscribed to: ' + newSubscription.topicName)
            this.subscriptions.push(newSubscription)
            return newSubscription
        }
        return null;
    }

    $unsubscribe(subscription: StompSubscription) {
        const aliveSubscription = this.subscriptions.find((sub) => {
            return sub.subscriptionName === subscription.subscriptionName
        })
        if (aliveSubscription) {
            aliveSubscription.subscription?.unsubscribe()
            //console.log('[ WSClient ] unsubscribe from: ' + subscription.topicName)
            /* remove from subscriptions list */
            this.subscriptions = this.subscriptions.filter(subscription => subscription.subscription?.id != aliveSubscription.subscription?.id)
            return
        }
        //console.warn('[ WSClient#unsubscribe ] not found: ' + subscription.topicName)
    }

    $disconnect() {
        this.stompClient.disconnect();
        this.connected = false;
        this.connecting = false;
        this.connectionsAttempt = 0;
        console.log('[ WSClient ] reconnectByTimeout: ' + this.reconnectByTimeout )
        if (this.reconnectByTimeout) {
            clearTimeout(this.reconnectByTimeout)
        }
    }
}
