import { render, staticRenderFns } from "./ChatMenu.vue?vue&type=template&id=43dc7da4&scoped=true&"
import script from "./ChatMenu.vue?vue&type=script&lang=ts&"
export * from "./ChatMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43dc7da4",
  null
  
)

export default component.exports