
import {Component, Vue} from "vue-property-decorator";
import {StompService} from "@/services/StompService";

@Component
export default class ProfileAvatar extends Vue {
  private socket: StompService | null = null

  mounted() {
    this.socket = this.$stompClient
    //console.log('[ ProfileAvatar#mounted ] socket', this.socket)
    this.$stompClient.$connect();
  }

  get badgeVariant() {
    if (!this.socket) {
      return 'light'
    }
    if (this.socket.$isConnected) {
      return 'success'
    }
    else if (this.socket.$isConnecting) {
      return 'warning'
    }
    return 'danger'
  }
}
